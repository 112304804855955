var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('center',{ref:"textPage",staticClass:"PrintVersionContainer",style:({ 'font-family': 'Montserrat, Verdana, Helvetica, Arial' })},[_c('table',{style:({
        'border-collapse': 'collapse',
        height: '100%',
        width: '100%',
        'text-size-adjust': '100%',
        'max-width': '700px !important',
      }),attrs:{"align":"center","border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('PrintNavbarMain'),_c('PrintHeroImage'),_c('PrintTitle',{attrs:{"itinerary":_vm.itinerary.data,"format-date":_vm.formatDate}}),_c('PrintDividerYellow'),_c('PrintDescription',{attrs:{"itinerary":_vm.itinerary.data}}),_c('PrintDividerPink'),_vm._l((_vm.daysWithFullServices),function(day,index){return _c('span',{key:index + 'a'},[_c('PrintItineraryDayImage',{attrs:{"day":day,"format-date":_vm.formatDate,"itinerary":_vm.itinerary.data}}),_c('PrintItineraryDayTitle',{attrs:{"day":day,"format-date":_vm.formatDate,"itinerary":_vm.itinerary.data}}),_c('PrintDividerPink'),_c('PrintItineraryDayDescription',{attrs:{"day":day,"format-date":_vm.formatDate,"itinerary":_vm.itinerary.data}}),_c('PrintItineraryDayServices',{attrs:{"day":day,"format-date":_vm.formatDate,"itinerary":_vm.itinerary.data}})],1)}),(
            _vm.helper.isSectionDisplayed(
              _vm.publishedConfigAndImages.config.contents,
              _vm.constants.TEMPLATE_CONFIG.DISPLAY_HOTELS_ACCOMMODATIONS
            )
          )?_c('div',[_c('PrintPricesTitle'),_c('PrintDividerGreen'),_c('PrintPrices',{attrs:{"itinerary":_vm.itinerary.data}})],1):_vm._e(),(
            _vm.helper.isSectionDisplayed(
              _vm.publishedConfigAndImages.config.contents,
              _vm.constants.TEMPLATE_CONFIG.DISPLAY_CONTACT_US
            )
          )?_c('div',[_c('PrintQuestionsTitle'),_c('PrintDividerYellow'),_c('PrintQuestionsInfo',{attrs:{"itinerary":_vm.itinerary.data}})],1):_vm._e()],2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"modal-card-head modalCardPrint"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Print Version")])])}]

export { render, staticRenderFns }